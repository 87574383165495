import { useLocalStorage } from "logic/internals/storage/use-local-storage";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const IS_BOOKMARKS_KEY = "history:is-bookmarks";

export function useBookmarksFlag() {
  const localStorage = useLocalStorage();

  const isBookmarks = useMemo(
    () => localStorage.getItem(z.boolean({ coerce: true }).optional(), IS_BOOKMARKS_KEY),
    [localStorage]
  );

  const markBookmarksAsTrue = useCallback(() => {
    localStorage.setItem(IS_BOOKMARKS_KEY, true);
  }, [localStorage]);

  const markBookmarksAsFalse = useCallback(() => {
    localStorage.setItem(IS_BOOKMARKS_KEY, false);
  }, [localStorage]);

  return useMemo(() => {
    return {
      isBookmarks,
      markBookmarksAsTrue,
      markBookmarksAsFalse,
    };
  }, [isBookmarks, markBookmarksAsTrue, markBookmarksAsFalse]);
}

// Ant Design
import { Input, InputProps, Space, theme } from "antd";
// react-hook-form
import { Control, Controller, FieldValues, Path } from "react-hook-form";

export interface CustomInputProps<T extends FieldValues = FieldValues> {
  name: Path<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<T, any>;
  rules?: Record<string, unknown>;
  inputProps: InputProps;
  errorMessage?: string;
  fullWidth?: boolean;
}

export const CustomInput = <T extends FieldValues = FieldValues>({
  name,
  control,
  rules,
  inputProps,
  errorMessage,
  fullWidth,
}: CustomInputProps<T>) => {
  const { token } = theme.useToken();
  return (
    <Space direction="vertical" style={{ width: fullWidth ? "100%" : "unset" }}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            {...inputProps}
            type={inputProps.type}
            placeholder={inputProps.placeholder}
            status={fieldState.invalid ? "error" : undefined}
          />
        )}
      />
      {!!errorMessage && <p style={{ color: token.red, marginBottom: 0 }}>{errorMessage}</p>}
    </Space>
  );
};
